import React, { useEffect, useRef } from 'react';
import confetti from 'canvas-confetti';

const ConfettiComponent = () => {
    const animationIdRef = useRef(null);

    useEffect(() => {
        const startConfetti = () => {
            const myConfetti = confetti.create(null, {
                resize: true,
                useWorker: true,
            });
            const shootConfetti = () => {
                myConfetti({
                    particleCount: 250,
                    startVelocity: 30,
                    spread: 360,
                    ticks: 200,
                    origin: {
                        x: Math.random(), // Random horizontal position
                        y: 0.2 // Top of the screen
                    },
                    gravity: 1.5
                });
            };
            animationIdRef.current = setInterval(shootConfetti, 3000);
        };

        const stopConfetti = () => {
            clearInterval(animationIdRef.current);
            animationIdRef.current = null;
        };

        const handleVisibilityChange = () => {
            if (document.hidden) {
                stopConfetti();
            } else {
                startConfetti();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        startConfetti(); // Start confetti on component mount

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            stopConfetti(); // Clean up on component unmount
        };
    }, []);

    return <canvas id="confetti-canvas" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', pointerEvents: 'none' }} />;
};

export default ConfettiComponent;
