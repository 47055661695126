import masehtotData from "./masehtot.json"

export function getPagesInChapter (chapter) {
    let pages = [];
    let foundStartPage = false;
    const allPages = masehtotData.allPages;

    if (chapter.pages) {
        return chapter.pages;
    }

    for (let i in allPages) {
        const page = allPages[i];
        if (foundStartPage) {
            pages.push(page);
            if (page === chapter.endPage) {
                break;
            }
        } else {
            if (page === chapter.startPage) {
                foundStartPage = true;
                pages.push(page);
                if (page === chapter.endPage) {
                    break;
                }
            }
        }
    };
    return pages;
}



