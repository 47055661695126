
const siumProgress = (props) => {

    let siumProgressClasses = ["progress", "green"];
    const siumPercClass = !props.siumPerc ? "percent-0" : (props.siumPerc < 15 ? "percent-10" :(props.siumPerc < 25 ? "percent-20" :
        (props.siumPerc < 35 ? "percent-30" : (props.siumPerc < 45 ? "percent-40" : (props.siumPerc < 55 ? "percent-50" : (props.siumPerc < 65 ? "percent-60" :
        (props.siumPerc < 75 ? "percent-70" : (props.siumPerc < 85 ? "percent-80" : (props.siumPerc < 95 ? "percent-90" : "percent-100")))))))));
    siumProgressClasses.push(siumPercClass)

    return(
        <div>
            <div className="container">
                <div className="row">
                    <div className="progress-container">
                        <div className={siumProgressClasses.join(' ')}>
                            <span className="progress-left">
                                <span className="progress-bar"></span>
                            </span>
                            <span className="progress-right">
                                <span className="progress-bar"></span>
                            </span>
                            <div className="progress-value pulse">{props.siumPerc}%</div>
                        </div>
                        <p className="bold progress-message">{props.message}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default siumProgress;
