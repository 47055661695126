import React from 'react';

const Spinner = () => {
    return (
        <div className="sium-logo spinner-container">
            <img className="sium-logo-img spinner-img"/>
        </div>
    );
};

export default Spinner;
