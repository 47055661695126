
import React, {useEffect, useState} from "react"
import globalMasehtot from "../masehtot.json"
import {useNavigate, useParams} from "react-router-dom";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {doc, getDoc, setDoc} from "firebase/firestore";
import {db} from "../index";
import Spinner from "../spinner";

const EditSium = () => {

    const params = useParams();
    const [sium, setSium] = useState([]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [errorMessage, setErrorMessage] = useState("");
    const [phone, setPhone] = useState('');
    const [haveChanges, setHaveChanges] = useState(false)
    const [haveDatesChanges, setHaveDatesChanges] = useState(false)
    const navigate = useNavigate();

    const handleSubmit = event => {
        event.preventDefault();

        if (siumValidation()) {
            const docRef = doc(db, "siumim", params.siumId);
            let editSium = sium;
            if (sium.kehilaName !== event.target.kehilaName.value) {
                editSium.kehilaName = event.target.kehilaName.value;
            }
            if (sium.organizerName !== event.target.fullName.value) {
                editSium.organizerName = event.target.fullName.value;
            }
            if (sium.organizerPhone !== phone) {
                editSium.organizerPhone = [phone];
            }
            if (sium.organizerEmail !== event.target.email.value) {
                editSium.organizerEmail = event.target.email.value;
            }
            if (sium.nishmat !== event.target.nishmat.value) {
                editSium.nishmat = event.target.nishmat.value;
            }
            editSium.startSium = startDate;
            editSium.endSium = endDate;
            setDoc(docRef, editSium).then(() => {
                navigate("/niulSium/" + params.siumId + "/siumStatus");
            }).catch((error) => {
                console.log(`Couldn't save sium element ${error}`)
            });
            setHaveChanges(false);
        }

    }

    function siumValidation() {
        if (startDate && endDate) {
            if (startDate.getTime() < endDate.getTime()) {
                return true;
            }
        }
        setErrorMessage("בחירת תאריכים שגויה");
        const toastModal = document.getElementById('errorModal');
        const toastBootstrap = window.bootstrap.Toast.getOrCreateInstance(toastModal);
        toastBootstrap.show();
    }

    const handlePhoneChange = (e) => {
        const inputValue = e.target.value;
        setHaveChanges(true);
        const numericValue = inputValue.replace(/\D/g, ''); // Remove non-digit characters
        setPhone(numericValue);
    };

    useEffect(() => {
        const docRef = doc(db, "siumim", params.siumId);
        getDoc(docRef).then(sium => {
            if (sium) {
                setSium(sium.data());
                setPhone(sium.data().organizerPhone);
                setStartDate(sium.data().startSium.toDate());
                setEndDate(sium.data().endSium.toDate());
            }
        });
    },[params.siumId]);

    if (sium && sium.kehilaName) {

        return (
            <div className="sium-main">
                <div className="sium-main-container">
                    <div className="basad-with-return margin-bottom-10">
                        <div className="basad">
                            <span>בס"ד</span>
                            <br/>
                            <span>להצלחת עם ישראל</span>
                        </div>
                        <div className="return-container">
                            <a className="btn sium-button return-button" href={"/niulSium/" + params.siumId + "/siumStatus"}>חזור</a>
                        </div>
                    </div>
                    <div className="sium-logo">
                        <a className="sium-logo-img" href={"/niulSium/" + params.siumId + "/siumStatus"}></a>
                    </div>
                    <div className="grid p-3 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-3 sium-titles">
                        <h2>סיום הש"ס של {sium.kehilaName}</h2>
                        <h4>עדכון פרטי קשר</h4>
                    </div>
                    <form onSubmit={handleSubmit} className="needs-validation margin-top-20" novalidate>
                        <div className="form-floating">
                            <input className="form-control" type="text" id="kehilaNameInput" name="kehilaName" aria-label="Organization Name"
                                   required={true} defaultValue={sium.kehilaName} onChange={() => setHaveChanges(true)}/>
                            <label className="edit-sium-field" for="kehilaNameInput">שם הקהילה/הארגון</label>
                        </div>
                        <div className="form-floating">
                            <input className="form-control" type="text" id="organizerNameInput" name="fullName" aria-label="Organizer Name"
                               required={true} defaultValue={sium.organizerName} onChange={() => setHaveChanges(true)}/>
                            <label className="edit-sium-field" htmlFor="organizerNameInput">שם מנהל סיום השס</label>
                        </div>
                        <div className="form-floating">
                            <input className="form-control right-text" type="tel" id="phoneInput" name="phone" aria-label="Phone"
                               required={true} value={phone} onChange={handlePhoneChange}/>
                            <label className="edit-sium-field" htmlFor="phoneInput">פלאפון</label>
                        </div>
                        <div className="form-floating">
                            <input className="form-control" type="email" id="emailInput" name="email" aria-label="Email"
                               required={true} defaultValue={sium.organizerEmail} onChange={() => setHaveChanges(true)}/>
                            <label className="edit-sium-field" htmlFor="emailInput">אימייל</label>
                        </div>
                        <div className="form-floating">
                            <input className="form-control" type="text" id="nishmatInput" name="nishmat"
                               aria-label="Nishmat" defaultValue={sium.nishmat} onChange={() => setHaveChanges(true)}/>
                            <label className="edit-sium-field" htmlFor="nishmatInput">לעילוי נשמת/לרפואת/להצלחת</label>
                        </div>
                        <div className="sium-dates-container">
                            <label className="bold">תאריך תחילת הלימוד</label><br/>
                            <DatePicker selectsStart selected={startDate} onChange={date => {setStartDate(date);setHaveChanges(true)}}
                                        startDate={startDate} onFocus={e => e.target.blur()}/>
                            <br/>
                            <label className="bold">תאריך סיום הלימוד</label><br/>
                            <DatePicker selectsEnd selected={endDate} onChange={date => {setEndDate(date);setHaveChanges(true)}} endDate={endDate}
                                        startDate={startDate} minDate={startDate} onFocus={e => e.target.blur()}/>
                        </div>

                        <div className="toast-container position-fixed top-0 start-50 translate-middle-x">
                            <div id="errorModal" className="toast" role="alert" aria-live="assertive" aria-atomic="true">
                                <button type="button" className="btn-close me-2 m-auto" data-bs-dismiss="toast"
                                        aria-label="Close"></button>
                                <div className="toast-body">
                                    {errorMessage}
                                </div>
                            </div>
                        </div>

                        {haveChanges && <div className="sium-button-container">
                            <button className="btn sium-button" type="submit">עדכן</button>
                        </div>}
                    </form>
                    <div className="sium-bottom-page margin-top-20">
                        <p><span> © כל הזכויות שמורות לאתר סיום הש"ס</span></p>
                    </div>
                </div>
            </div>
        )
    } else {
        return (<Spinner/>);
    }

}

export default EditSium;
