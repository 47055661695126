
const userProgress = (props) => {

    let userProgressClasses = ["progress", "green"];
    const userPercClass = !props.userPerc ? "percent-0" : (props.userPerc < 15 ? "percent-10" :(props.userPerc < 25 ? "percent-20" :
        (props.userPerc < 35 ? "percent-30" : (props.userPerc < 45 ? "percent-40" : (props.userPerc < 55 ? "percent-50" : (props.userPerc < 65 ? "percent-60" :
        (props.userPerc < 75 ? "percent-70" : (props.userPerc < 85 ? "percent-80" : (props.userPerc < 95 ? "percent-90" : "percent-100")))))))));
    userProgressClasses.push(userPercClass)

    let recommendationClasses = ["progress", "red"];
    const recommendedPercClass = !props.recommendation ? "percent-0" : (props.recommendation < 15 ? "percent-10" :
        (props.recommendation < 25 ? "percent-20" : (props.recommendation < 35 ? "percent-30" : (props.recommendation < 45 ? "percent-40":
        (props.recommendation < 55 ? "percent-50" : (props.recommendation < 65 ? "percent-60" : (props.recommendation < 75 ? "percent-70" :
        (props.recommendation < 85 ? "percent-80" : (props.recommendation < 95 ? "percent-90" : "percent-100")))))))));
    recommendationClasses.push(recommendedPercClass);

    return(
        <div>
            <div className="container user-progress-container">
                <div className="row">
                    <div className="progress-container">
                        <div className={userProgressClasses.join(' ')}>
                            <span className="progress-left">
                                <span className="progress-bar"></span>
                            </span>
                            <span className="progress-right">
                                <span className="progress-bar"></span>
                            </span>
                            <div className="progress-value pulse">{props.userPerc}%</div>
                        </div>
                        <p className="bold progress-message">סטטוס אישי</p>
                    </div>
                </div>

                <div className="row">
                    <div className="progress-container">
                        <div className={recommendationClasses.join(' ')}>
                            <span className="progress-left">
                                <span className="progress-bar"></span>
                            </span>
                            <span className="progress-right">
                                <span className="progress-bar"></span>
                            </span>
                            <div className="progress-value pulse">{props.recommendation}%</div>
                        </div>
                        <p className="bold progress-message">סטטוס מומלץ</p>
                        {props.displayJumpPage && <p className="page-jump-msg">חפש את הדף הקופץ</p> }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default userProgress;
