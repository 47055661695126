import './App.css';
import React from "react";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import UpdateStatus from "./userStatus/updateStatus";
import AddUser from "./niulSium/addEditUser/addUser";
import NewSium from "./newSium/newSium";
import SiumStatus from "./niulSium/siumStatus/siumStatus";
import EditSium from "./newSium/editSium";
import AddUserByAdmin from "./niulSium/addEditUser/addUserByAdmin";
import Welcome from "./welcome";
import EditUserByAdmin from "./niulSium/addEditUser/editUserByAdmin";
import DisplaySium from "./niulSium/siumStatus/displaySium";

window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

function App() {
    const viewport = document.querySelector('meta[name="viewport"]');
    if ( viewport ) {
        viewport.content = 'initial-scale=1';
        viewport.content = 'width=device-width';
    }
    document.title = 'סיום הש"ס - לימוד גמרא מחולק';
  return (
      <Router>
          <div>
              <Routes>
                  <Route path="/updateStatus/:userId" element={<UpdateStatus/>} />
                  <Route path="/niulSium/:siumId/addUserByAdmin" element={<AddUserByAdmin/>} />
                  <Route path="/addUser/:siumId" element={<AddUser/>} />
                  <Route path="/niulSium/:siumId/siumStatus" element={<SiumStatus/>} />
                  <Route path="/niulSium/:siumId/editSium" element={<EditSium/>} />
                  <Route path="/niulSium/editUser/:userId" element={<EditUserByAdmin/>} />
                  <Route path="/displaySium/:siumId" element={<DisplaySium/>} />
                  <Route path="/newSium" element={<NewSium/>}/>
                  <Route path="*" element={<Welcome/>}/>
              </Routes>
          </div>
      </Router>
  );
}

export default App;
