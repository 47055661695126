
import { doc, getDoc, addDoc, setDoc, collection } from "firebase/firestore";
import {db} from "../../index";
import React, {useEffect, useState} from "react"
import {useParams} from 'react-router-dom';
import globalMasehtot from "../../masehtot.json"
import {getPagesInChapter} from "../../masehtotFunctions";
import { useNavigate } from "react-router-dom";
import Spinner from "../../spinner";

const AddUser = () => {

    const params = useParams();
    const [sium, setSium] = useState([]);
    const [privateSelectedMasehtot, setPrivateSelectedMasehtot] = useState([]);
    const [privateSelectedChapters, setPrivateSelectedChapters] = useState([]);
    const [generalSelectedMasehtot, setGeneralSelectedMasehtot] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [phone, setPhone] = useState('');
    const navigate = useNavigate();

    const handleSubmit = event => {
        event.preventDefault();

        masehtotValidation().then(siumObj => {
            if (siumObj) {
                const userSelections = getUserSelection(siumObj);
                if (userSelections) {
                    const newUser = {
                        email: event.target.email.value,
                        phone: phone,
                        fullName: event.target.fullName.value,
                        nishmat: event.target.nishmat.value,
                        siumId: params.siumId,
                        lastModified: new Date(),
                        masehtot: userSelections.masehtot,
                        pagesNum: userSelections.pagesCounter
                    }
                    addDoc(collection(db, "users"), newUser).then((docRef) => {
                        navigate("/updateStatus/" + docRef.id);
                    });

                } else {
                    errorMessageWithReset("ישנה בעיה בבחירת המסכתות")
                }
            } else {
                errorMessageWithReset("כנראה שבחרת מסכתות שנתפסו, הדף עכשיו התרענן כדי שתוכל לבחור שוב")
            }
        })
    };

    function errorMessageWithReset(errorMsg) {
        setPrivateSelectedChapters([]);
        setPrivateSelectedMasehtot([])
        setErrorMessage(errorMsg);
        const toastModal = document.getElementById('errorModal');
        const toastBootstrap = window.bootstrap.Toast.getOrCreateInstance(toastModal);
        toastBootstrap.show();
    }

    function masehtotValidation() {
        const docRef = doc(db, "siumim", params.siumId);
        return getDoc(docRef).then(sium => {
            if (sium) {
                const siumObj = sium.data();
                setSium(siumObj);
                const genSelection = siumObj.masehtotSelection;
                setGeneralSelectedMasehtot(genSelection);
                let validationResult = true;
                if (privateSelectedChapters.length === 0) {
                    validationResult = false;
                }
                for (const i in privateSelectedChapters) {
                    const selection = privateSelectedChapters[i].split("-");
                    if (!selection[0] || !selection[1] || genSelection[selection[0]][selection[1]]) {
                        validationResult = false;
                        break;
                    }
                }
                if (validationResult) {
                    return siumObj;
                }
                return null;
            }
        });
    }

    function getUserSelection(siumObj) {
        let masehtotSelection = [];
        let specificMasehet = {};
        let pagesCounter = 0;
        for (const i in privateSelectedChapters.sort()) {
            const selection = privateSelectedChapters[i].split("-");
            const masehetKey = selection[0];
            const chapterNum = selection[1];
            const currentMasehet = globalMasehtot.allMasehtot[masehetKey];
            if (currentMasehet) {
                const currentChapter = currentMasehet.chapters[chapterNum];
                if (currentChapter) {
                    const pagesInChapter = getPagesInChapter(currentChapter);
                    pagesCounter += pagesInChapter.length;
                    let finish = [];
                    for (let i = 0; i < pagesInChapter.length; i++) {finish.push(false)}
                    // Continue with same chapter
                    if (specificMasehet.masehetId !== masehetKey) {
                        // User select one more masehet
                        if (specificMasehet.masehetId) {
                            masehtotSelection.push(specificMasehet);
                        }
                        specificMasehet = {
                            masehetId: masehetKey,
                            chapters: [],
                        }
                    }
                    specificMasehet.chapters.push({chapterNum: parseInt(chapterNum) + 1, finish: finish})
                    siumObj.masehtotSelection[masehetKey][chapterNum] = true;
                }
            }
        }
        if (specificMasehet.masehetId) {
            masehtotSelection.push(specificMasehet);
        }
        setDoc(doc(db, "siumim", params.siumId), siumObj);
        setSium(siumObj);
        return {masehtot: masehtotSelection, pagesCounter: pagesCounter};
    }

    function handleMasehetSelection(key, action) {
        if (action === "remove") {
            setPrivateSelectedMasehtot(privateSelectedMasehtot.filter((c) => c !== key));
            const newPrivateSelectionC = privateSelectedChapters.filter((p) => !p.startsWith(key));
            setPrivateSelectedChapters(newPrivateSelectionC);
        } else {
            const currentGeneralSelection = generalSelectedMasehtot[key];
            let newPrivateSelectionC = privateSelectedChapters;
            for (const chap_i in currentGeneralSelection) {
                if (!currentGeneralSelection[chap_i]) {
                    newPrivateSelectionC.push(key + "-" + chap_i);
                }
            }
            setPrivateSelectedChapters(newPrivateSelectionC);
            if (action === "all") {
                setPrivateSelectedMasehtot([...privateSelectedMasehtot, key])
            } else {
                setPrivateSelectedMasehtot(privateSelectedMasehtot.filter((m) => m !== key));
            }
        }
    }

    function handleChapterSelection(m_key, c_i) {
        const chapterVal = m_key + "-" + c_i;
        let newPrivateSelectionC = [];
        if (privateSelectedChapters.includes(chapterVal)) {
            newPrivateSelectionC = privateSelectedChapters.filter((p) => p !== chapterVal);
            if (privateSelectedMasehtot.includes(m_key)) {
                setPrivateSelectedMasehtot(privateSelectedMasehtot.filter((c) => c !== m_key));
            }
        } else {
            newPrivateSelectionC = [...privateSelectedChapters, chapterVal];
            let wholeMasehetSelected = true;
            const currentGeneralSelection = generalSelectedMasehtot[m_key];
            for (const chap_i in currentGeneralSelection) {
                if (!privateSelectedChapters.includes(m_key + "-" + chap_i)) {
                    wholeMasehetSelected = false;
                    break;
                }
            }
            if (wholeMasehetSelected) {
                setPrivateSelectedMasehtot([...privateSelectedMasehtot, m_key])
            }
        }
        setPrivateSelectedChapters(newPrivateSelectionC);
    }

    function sharePage() {
        if (navigator.share) {
            navigator.share({
                title: 'סיום הש"ס "' + sium.kehilaName + '"',
                text: 'הוספת לומד חדש',
                url: '/addUser/' + params.siumId
            });
        }
    }

    const handlePhoneChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, ''); // Remove non-digit characters
        setPhone(numericValue);
    };

    useEffect(() => {
        const docRef = doc(db, "siumim", params.siumId);
        getDoc(docRef).then(sium => {
            if (sium) {
                setSium(sium.data())
                setGeneralSelectedMasehtot(sium.data().masehtotSelection);
            }
        });

    },[params.siumId]);

    if (sium && sium.kehilaName && globalMasehtot.allMasehtot) {
        const masehtotKeysList = Object.keys(globalMasehtot.allMasehtot);
        const masehtotList = masehtotKeysList.map((key, index) => {
            const currentMasehet = globalMasehtot.allMasehtot[key];
            const currentGeneralSelection = generalSelectedMasehtot[key];
            const chapters = globalMasehtot.allChapters;
            let allMasehetWasSelected = true;
            let partialMasehetWasSelected = false;
            const chaptersForMasehet = currentGeneralSelection.map((selected, c_i) => {
                const chapterName = chapters[c_i];
                const chapterId = key + "-" + c_i

                if (selected) {
                    partialMasehetWasSelected = true;
                } else {
                    allMasehetWasSelected = false;
                }

                return <>
                    <input type="checkbox" className="btn-check" id={chapterId} checked={privateSelectedChapters.includes(chapterId)}
                           onChange={() => handleChapterSelection(key, c_i)} disabled={selected}/>
                    <label className="btn btn-outline-primary" htmlFor={chapterId}>{chapterName}</label>
                </>
            })

            return <div className="accordion-item" key={key + "-masehet"}>
                <h3 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target={"#collapse" + key} aria-expanded="false" aria-controls={"collapse" + key}>
                        {currentMasehet.name}
                        {allMasehetWasSelected && <span className="masehet-was-selected">(המסכת נתפסה)</span>}
                    </button>
                </h3>
                <div id={"collapse" + key} className="accordion-collapse collapse" data-bs-parent="#masehtotList">
                    <div className="accordion-body">
                        <div className="btn-group margin-bottom-10" role="group" aria-label="Basic radio toggle button group">
                            <input type="radio" className="btn-check" name={key + "Selection"} id={key + "AllMasehet"} value={key + "AllMasehet"}
                                disabled={allMasehetWasSelected || partialMasehetWasSelected} onChange={() => handleMasehetSelection(key, "all")}
                                checked={privateSelectedMasehtot.includes(key)}/>
                            <label className="btn btn-outline-primary" htmlFor={key + "AllMasehet"}>בחירת כל המסכת</label>

                            <input type="radio" className="btn-check" name={key + "Selection"} id={key + "PartialMasehet"} disabled={allMasehetWasSelected}
                                value={key + "PartialMasehet"} onChange={() => handleMasehetSelection(key, "partial")}
                                checked={!privateSelectedMasehtot.includes(key) && privateSelectedChapters.some((c) => c.startsWith(key))}/>
                            <label className="btn btn-outline-primary" htmlFor={key + "PartialMasehet"}>בחירת פרקים מסוימים</label>

                            <input type="radio" className="btn-check" name={key + "Selection"} id={key + "NoMasehet"} disabled={allMasehetWasSelected}
                                value={key + "NoMasehet"} onChange={() => handleMasehetSelection(key, "remove")}
                                checked={!privateSelectedChapters.some((c) => c.startsWith(key))}/>
                            <label className="btn btn-outline-primary" htmlFor={key + "NoMasehet"}>מסכת לא נבחרה</label>
                        </div>
                        <div id={key + "Chapters"}>
                            {chaptersForMasehet}
                        </div>
                    </div>

                </div>
            </div>
        });
        return(
            <div className="sium-main">
                <div className="sium-main-container">
                    <div className="basad-with-return">
                        <div className="basad">
                            <span>בס"ד</span>
                            <br/>
                            <span>להצלחת עם ישראל</span>
                        </div>
                    </div>
                    <div className="sium-logo">
                        <a className="sium-logo-img" href={"/"}></a>
                    </div>
                    <div className="grid p-3 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-3 sium-titles">
                        <h2>סיום הש"ס של {sium.kehilaName}</h2>
                        <h4 >הוספת לומד חדש</h4>
                        <button className="btn sium-button" type="button" onClick={() => sharePage()}>שתף דף</button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <input className="form-control" type="text" placeholder="שם מלא" name="fullName" aria-label="Full name" required={true}/>
                        <input className="form-control" type="text" placeholder="פלאפון" name="phone" aria-label="Phone" value={phone} onChange={handlePhoneChange} required={true}/>
                        <input className="form-control" type="text" placeholder="אימייל" name="email" aria-label="Email"/>
                        <input className="form-control" type="text" placeholder="לעילוי נשמת/לרפואת/להצלחת" name="nishmat" aria-label="Nishmat"/>
                        <div className="accordion" id="masehtotList">
                            {masehtotList}
                        </div>
                        <div className="toast-container position-fixed top-0 start-50 translate-middle-x">
                            <div id="errorModal" className="toast" role="alert" aria-live="assertive" aria-atomic="true">
                                <button type="button" className="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                                <div className="toast-body">
                                    {errorMessage}
                                </div>
                            </div>
                        </div>
                        {privateSelectedChapters.length > 0 && <div className="sium-button-container sticky-bottom">
                            <button className="btn sium-button" type="submit">הוסף</button>
                        </div>}
                    </form>
                    <div className="sium-bottom-page margin-top-20">
                        <p><span> © כל הזכויות שמורות לאתר סיום הש"ס</span></p>
                    </div>
                </div>
            </div>
        )
    } else {
        return (<Spinner/>);
    }
}

export default AddUser;
